<template>
  <div></div>
</template>

<script>
export default {
  mounted() {
    const nextUrl = this.$route.query.next

    this.$router.push('/home', () => {
      if (nextUrl) {
        this.$router.push(nextUrl)
      }
    })
  },
}
</script>
